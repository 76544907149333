<template>
  <!-- 註冊页面 -->
  <div>
    <div class="border-0 mb-xl-3 mb-1">
      <img :src="logo" style="width: 180px; height: 60px; margin: 30px auto; cursor: pointer"
        @click="this.$router.push('/')" :title="$t('go_home')" />

      <form method="POST" @submit="Register" ref="reg" class="login_form" @keyup.enter="emailvalselectfun('key')">
        <div>
          <div class="form-floating">
            <img src="@/assets/img/main/userid.png" class="login_left_img" />
            <input type="text" autocomplete="on" tabindex="0" placeholder="  " v-model="uid" maxlength="10"
              required="required" id="uid" class="form-control" />
            <label for="uid" class="text-secondary">{{
              $t("text_userid")
            }}</label>
          </div>
          <p class="place_text ps-1">
            {{ $t("Member_registerAccountPlaceHolder") }}
          </p>
        </div>
        <div>
          <div class="form-floating">
            <img src="@/assets/img/main/pw.png" class="login_left_img" />
            <input type="password" placeholder="  " v-model="pwd" maxlength="12" required="required" id="pwd"
              class="form-control" />
            <label for="pwd" class="text-secondary">{{
              $t("passwordTitle")
            }}</label>
          </div>
          <p class="place_text ps-1">
            {{ $t("Member_registerPasswordPlaceHolder") }}
          </p>
        </div>
        <div>
          <div class="form-floating">
            <img src="@/assets/img/main/pw.png" class="login_left_img" />
            <input type="password" placeholder="  " v-model="pwd1" maxlength="12" required="required" id="confirmpwd"
              class="form-control" />
            <label for="confirmpwd" class="text-secondary">{{
              $t("comfpwd")
            }}</label>
          </div>

          <p class="place_text ps-1"></p>
        </div>
        <div v-if="set_regi.have_name">
          <div class="form-floating">
            <input type="text" placeholder="  " v-model="cname" required="required" id="name_id" class="form-control" />
            <label for="name_id" class="text-secondary">{{
              $t("text_realNameTitle")
            }}</label>
          </div>
          <p class="place_text ps-1">
            {{ $t("Member_registerRealNamePlaceHolder") }}
          </p>
        </div>
        <div v-if="set_regi.have_phone">
          <div class="form-floating">
            <img src="@/assets/img/main/phone.png" class="login_left_img" />
            <input type="text" placeholder="  " v-model="phone" required="required" id="number_id" class="form-control" />
            <label for="number_id" class="text-secondary">{{
              $t("reg_phone")
            }}</label>
          </div>
          <p class="place_text ps-1">
            {{ $t("Member_registerPhoneNumberPlaceHolder") }}
          </p>
        </div>
        <div v-if="set_regi.have_birthday">
          <div class="form-floating">
            <input type="text" placeholder="  " v-model="birthday" required="required" id="birth" class="form-control" />
            <label for="birth" class="text-secondary">{{
              $t("text_birth")
            }}</label>
          </div>
          <p class="place_text ps-1">
            {{ $t("Member_registerBirthdayPlaceHolder") }}
          </p>
        </div>
        <div class="position-relative" v-if="set_regi.have_email">
          <div class="form-floating">
            <img src="@/assets/img/main/mail.png" class="login_left_img" />
            <input type="text" placeholder="  " tabindex="0" v-model="email" required="required" id="email"
              autocomplete="off" class="form-control" @keyup="emailvalselectfun" @keyup.down="emailvalselectfun('down')"
              @keyup.up="emailvalselectfun('up')" />
            <label for="email" class="text-secondary">{{ $t("email") }}</label>
          </div>

          <div class="flex-column text-start" id="email_select">
            <div v-for="(l, i) in emailselectcompute" :key="i" :value="l" :class="{ active: i == emil_index }"
              @mouseover="emil_index = i" @mousedown="emailvalselectfun('mouse', l)">
              {{ email.split("@")[0] }}{{ l }}
            </div>
          </div>
          <p class="place_text ps-1">
            {{ $t("Member_registerEmailPlaceHolder") }}
          </p>
        </div>
        <div>
          <div class="form-floating">
            <img src="@/assets/img/main/refer.png" class="login_left_img" />
            <input type="text" placeholder="  " v-model="refcode" id="refcode" class="form-control"
              :disabled="dis_refcode" />
            <label for="refcode" class="text-secondary">{{
              $t("referenceCodeTitle")
            }}</label>
          </div>
          <p class="place_text ps-1"></p>
        </div>

        <div class="form-floating mt-3">
          <img src="@/assets/img/main/captcha.png" class="login_left_img" />
          <input type="text" ref="captcha" v-model="captcha" maxlength="4" placeholder="  " required="required"
            id="captcha" class="form-control" />
          <label for="captcha" class="text-secondary">{{
            $t("text_Captcha")
          }}</label>
          <captchaview ref="captgetcode" />
        </div>
        <input type="submit" class="col-12 mt-3 text-white border-0 rounded-2 login_btn fw-bolder"
          :value="$t('register')" />
      </form>

      <div class="login_or_line">
        <div class="d-flex flex-row">
          <div class="login_line flex-grow-1 position-relative"></div>
          <div class="mx-3">{{ $t("Member_or") }}</div>
          <div class="login_line flex-grow-1 position-relative"></div>
        </div>
      </div>

      <div class="mb-2">
        <button class="border-0 d-inline-block p-0 position-relative text-primary bg-body pointer-events bg-transparent"
          type="button">
          <img class="login_fb_img d-inline-block position-relative align-baseline"
            src="@/assets/img/main/messenger.png" />
          <span class="login_fb_text fw-bolder">{{
            $t("Member_fb_login")
          }}</span>
        </button>
      </div>

      <router-link to="" class="login_forget">
        <font>{{ $t("forgetpwd") }}</font>
      </router-link>
    </div>

    <div class="border-0 mb-3 fs-tenfive" style="padding: 15px 40px">
      <p class="m-0 fw-bolder">
        {{ $t("already") }}
      </p>
      <router-link to="/Login" class="login_forget">
        <font class="fs-tenfive fw-bolder" style="color: #1a1080; font-weight: 600">{{ $t("login") }}</font>
      </router-link>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import captchaview from "./Home/captcha.vue";
import { mapActions, mapGetters } from "vuex";
import api from "../components/http/api";

export default {
  name: "register",
  data() {
    return {
      set_regi: "",
      uid: "",
      pwd: "",
      pwd1: "",
      cname: "",
      phone: "",
      birthday: "",
      email: "",
      refcode: localStorage.getItem("introducer") ?? "",
      dis_refcode:
        localStorage.getItem("introducer") != null &&
          localStorage.getItem("introducer") != ""
          ? true
          : false,
      list: [
        "@gmail.com",
        "@hotmail.com",
        "@outlook.com",
        "@live.com",
        "@yahoo.com",
        "@icloud.com",
        "@facebook.com",
        "@me.com",
      ],
      emil_index: null,
    };
  },
  async created() {
    await this.GetIP();
    if (localStorage.getItem("ip") != null) {
      if (localStorage.getItem("ip").length < 7) {
        this.$MSG.API_popmsg(
          "IP is error, Please try again or change another network",
          "",
          "error"
        );
      }
    }
    this.Set_Register();
    this.getLogo();
  },
  methods: {
    ...mapActions(["getLogo"]),
    async GetIP() {
      await api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    Set_Register: function () {
      let param = {
        action: "Set_Register",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.set_regi = res;
      });
    },
    Register: function (e) {
      e.preventDefault();
      var re = /^[a-zA-Z0-9]+$/;

      var error_str = "";
      if (this.pwd != this.pwd1) {
        this.pwd = this.pwd1 = "";
        error_str +=
          this.$t("Member_registerCheckPasswordPatternErrMessage") + "</br>";
      }
      if (!re.test(this.uid) || this.uid.length < 4) {
        error_str +=
          this.$t("Member_registerAccountPatternErrMessage") + "</br>";
      }
      if (!re.test(this.pwd) || this.pwd.length < 6) {
        error_str +=
          this.$t("Member_registerPasswordPatternErrMessage") + "</br>";
      }
      if (!this.validateEmail(this.email) && this.set_regi.have_email) {
        error_str += this.$t("Member_registerEmailPatternErrMessage") + "</br>";
      }
      if (!this.checkcode()) {
        error_str += this.$t("text_error_cap") + "</br>";
      }
      if (error_str != null && error_str != "")
        this.$MSG.API_popmsg(error_str, "", "error");
      else {
        this.checkForm();
      }
    },
    checkForm: function () {
      localStorage.setItem("device", this.$Utils.isMobile());
      let param = {
        action: "Register",
        body: {
          uid: this.uid,
          pwd: this.pwd,
          cname: this.cname,
          email: this.email,
          tel: this.phone,
          birthday: this.birthday,
          wechat: "",
          qq: "",
          regdomain: document.location.origin,
          affiliate: localStorage.getItem("agent") ?? "",
          // sms: this.sms,
          ip: localStorage.getItem("ip"),
          device: localStorage.getItem("device") + ", " + navigator.userAgent,
          token: "",
          introducer: this.refcode,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          localStorage.removeItem("introducer");
          Cookies.set("code", res.session_code);
          localStorage.setItem("uid", this.uid);
          localStorage.setItem("utype", 0);
          localStorage.setItem("big_logo", 1);
          this.$MSG.API_popmsg(
            this.$t("Member_registerSuccessTitle"),
            "home",
            "success"
          );
        } else {
          this.$MSG.API_popmsg(status, "", "error");
        }
      });
    },
    validateEmail(value) {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(value)) {
        return false;
      } else {
        return true;
      }
    },
    checkcode: function () {
      return this.$refs.captgetcode.getdata() == this.captcha ? true : false;
    },
    emailvalselectfun: function (e, val) {
      if (e == "mouse") {
        this.email = this.email.split("@")[0] + val;
        document.getElementById("email_select").style.display = "none";
      } else if (e == "down") {
        if (
          this.emil_index == null ||
          this.emil_index == this.emailselectcompute.length - 1
        ) {
          this.emil_index = 0;
        } else {
          this.emil_index = this.emil_index + 1;
        }
      } else if (e == "up") {
        if (this.emil_index == null || this.emil_index == 0) {
          this.emil_index = this.emailselectcompute.length - 1;
        } else {
          this.emil_index = this.emil_index - 1;
        }
      } else if (e == "key") {
        if (this.email != null && this.email != "") {
          if (this.emil_index != null) {
            this.email =
              this.email.split("@")[0] +
              this.emailselectcompute[this.emil_index];
          }
          document.getElementById("email_select").style.display = "none";
        }
      } else {
        if (this.email != "" && this.email != null) {
          document.getElementById("email_select").style.display = "flex";
        } else {
          document.getElementById("email_select").style.display = "none";
        }
      }
    },
  },
  watch: {
    emailselectcompute() {
      this.emil_index = null;
    },
  },
  computed: {
    ...mapGetters(["logo"]),
    emailselectcompute() {
      return this.list.filter((v) => {
        if (this.email.includes("@")) {
          return v
            .toLowerCase()
            .includes(this.email.toLowerCase().split("@")[1]);
        } else return v;
      });
    },
  },
  components: { captchaview },
};
</script>
